'use strict';

/** Static utility methods */
export class Util {
    // The hosts allowed for fetch() operations
    static #allowedFetchHosts = [
        location.host,
        /niddk\.nih\.gov$/
    ];

    /**
     * Determines if the specified URL has a hostname that is allowed for fetching.
     * @param {string|URL} url
     * @returns {boolean} `true` if the host is allowed for fetching.
     */
    static isAllowedHost(url) {
        const host = new URL(url, location.href).host;
        for (const v of this.#allowedFetchHosts) {
            if (host === v || (v instanceof RegExp && host.match(v))) return true;
        }
        return false;
    }

    /**
     * Creates a debounced function that delays invoking `func` until after `wait` milliseconds
     * have elapsed since the last time the debounced function was invoked. Optionally, it can
     * invoke `func` immediately on the leading edge instead of the trailing.
     *
     * @param {Function} func The function to debounce.
     * @param {number} wait The number of milliseconds to delay.
     * @param {boolean} [immediate=false] If `true`, trigger the function on the leading edge, instead of the trailing.
     * @returns {Function} Returns the new debounced function.
     */
    static debounce(func, wait, immediate) {
        let timeout;
        wait = wait >= 0 ? wait : 0;
        if (typeof func !== 'function') return function() {};
        return function () {
            const context = this, args = arguments;
            const later = function () {
                timeout = null;
                if (!immediate) func.apply(context, args);
            };
            const callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow) func.apply(context, args);
        };
    }

    /**
     * Assembles a log string for representing a value and optional key/prefix in the format [{prefix}=]'{val}'.
     * @param {*} val The value to be logged.
     * @param {string} prefix The key/prefix to associate with the value.
     * @returns {string} The log string.
     */
    static logVal(val, prefix) {
        let log = prefix ? prefix + '=' : '';
        if (typeof val === 'string') log += '\'' + val + '\'';
        else log += val;
        return log;
    }

    /**
     * Writes the properties of an object as a comma-separated log string.
     * @param {object} obj
     * @returns The log string, with values separated by a comma and a space.
     * @see {@link logVal}
     */
    static logVals(obj) {
        return typeof obj === 'object' ?
            Object.entries(obj).map(([k, v]) => this.logVal(v, k)).join(', ') : '';
    }

    /**
     * Recursively freezes an object and its descendent object properties.
     * @param {object} obj The object to freeze.
     * @returns {object} The original object with its properties frozen.
     */
    static deepFreeze(obj) {
        if (obj === null || typeof obj !== 'object') return obj;
        Object.values(obj).forEach((val) => this.deepFreeze(val));
        if (!Object.isFrozen(obj)) Object.freeze(obj);
        return obj;
    }

    /**
     * Gets the first matching entry from an object whose key name matches the specified string or regular expression.
     * @param {object} obj The object to search.
     * @param {string|RegExp} key The key to find.
     * @returns {object[]} An array with two items, the matching key and the key's value, like `[key, value]`.
     */
    static getMatchingEntry(obj, key) {
        if (!obj || typeof obj !== 'object') return [];
        return Object.entries(obj).filter(([k]) => k.match(key))[0] || [];
    }

    /**
     * Gets the full URL for a UX asset given a relative path and an optional base URL.
     * @param {string|URL} path The path to the asset, e.g. "styles/niddk-main.css".
     * @param {string|URL} [uxBase] The base URL and path; if omitted, {@link autoUxBaseUrl} is used.
     * @returns {string} The full URL for the UX asset.
     */
    static getUxAssetUrl(path, uxBase) {
        path = typeof path === 'string' || path instanceof URL ? path : '';
        const base = new URL(uxBase || this.autoUxBaseUrl, location.href);
        return new URL(path, base).href;
    }

    /**
     * Gets the UX asset base URL (i.e., host and versioned path) by checking other resources on the page.
     * @returns {string} The absolute base URL.
     */
    static get autoUxBaseUrl() {
        try {
            const [url] = import.meta.url.split('/scripts');
            if (url) return new URL(url + '/', location.href).href;
        } catch {}
        try {
            for (const link of document.querySelectorAll('link[rel=stylesheet]')) {
                if ((link.href || '').match(/niddk-web\.css/i)) {
                    const url = new URL(link.href, location.href);
                    url.pathname = url.pathname.split('/styles')[0] + '/';
                    url.search = '';
                    url.hash = '';
                    return url.href;
                }
            }
        } catch {}
        return new URL('/', location.href).href;
    }
}

/**
 * NIDDK chat controller class and implementation for the `<niddk-chatcontrol>` custom element.
 * @extends HTMLElement
 */
class NiddkChatControl extends HTMLElement {
    static #hrefDefault = 'mailto:healthinfo@niddk.nih.gov'; // Default badge click href
    static #noPrechatHref = 'chat:'; // href representing no pre-chat pop-up (i.e., chat service loads immediately_)

    // Chat service URL defaults
    static #chatServerBaseUrl = 'https://livechat.niddk.nih.gov';
    static #srcDefault = new URL('/chatbeacon/NIDDK/1/scripts/chatbeacon.js?accountId=1&siteId=1&queueId=1&m=0&i=1&b=1&c=1&theme=frame', this.#chatServerBaseUrl).href;

    /**
     * Gets the custom attributes implemented for this element.
     * @returns {string[]} The list of custom attributes.
     */
    static get observedAttributes() {
        return ['debug', 'disable-chat', 'href', 'href-on', 'href-off', 'src', 'src-en', 'src-es', 'statuslinkid'];
    }

    static #instance; // Singleton instance of NiddkChatControl
    static #instancePromise; // Promise for awaiting the singleton instance to be set

    /**
     * Gets the configured instance of this class once it has been connected to the page.
     * @returns {Promise<NiddkChatControl>} The configured instance.
     */
    static async getInstance() {
        // Check for the instance to be set every 100ms until it has been
        return this.#instancePromise ??= new Promise((resolve, reject) => {
            const timeoutMs = 5000, // 5s
                tryMs = 100, // 100ms
                maxTries = Math.ceil(timeoutMs / tryMs);
            let tries = 0;
            const check = () => {
                tries++;
                if (this.#instance) resolve(this.#instance);
                else if (tries > maxTries) {
                    reject(`No chat controller found in ${timeoutMs / 1000}s`);
                    this.#instancePromise = null;
                }
                else setTimeout(check, tryMs);
            };
            check();
        });
    }

    /**
     * Gets the page language based on the classes applied to the `<body>` element.
     * @returns {"en"|"es"} Either "en" or "es".
     */
    static get pageLang() {
        return document.body.classList.contains('page-lang-es') ? 'es' : 'en';
    }

    // Static initialization code
    static {
        // Set up floating badge instance
        let float = document.querySelector('.chat-floating'), addFloat;
        if (!float) {
            float = document.createElement('div');
            float.className = 'chat-floating';
            addFloat = true;
        }

        // Use either existing floating instance, clone of footer instance, or new instance
        let floatBadge = float.querySelector('niddk-chatbadge') ??
            document.querySelector('footer niddk-chatbadge')?.cloneNode() ??
            document.createElement('niddk-chatbadge');

        // Add the sticky div to the main element (if necessary) and add the badge instance to it
        if (addFloat) document.querySelector('main')?.appendChild(float);
        float.appendChild(floatBadge);

        // Monitor badge status and update page accordingly
        this.getInstance().then((ncc) => {
            const setBadge = (show) => document.body.classList[show ? 'add' : 'remove']('livechat-showbadge');
            setBadge(ncc.status.badge);
            ncc.addEventListener('badge', (e) => setBadge(e.detail));
        }).catch((err) => console.error(`Error setting badge status handler`, err));
    }

    /** The number of minutes after which to automatically re-fetch the chat status. */
    refreshMinutes = 30;

    // Default values for configuration attributes and chat status
    #hrefs = {on: NiddkChatControl.#hrefDefault, off: NiddkChatControl.#hrefDefault};
    #srcs = {en: NiddkChatControl.#srcDefault, es: NiddkChatControl.#srcDefault};
    #status = Util.deepFreeze({online: {en: false, es: false}, badge: false, timestamp: ''});

    #initialized = false; // Flag to indicate if the component has initialized
    #statusUrl; // The URL used to fetch the chat status
    #dialog; // The pre-chat pop-up dialog element

    constructor() {
        super();
    }

    /**
     * Writes debug messages to the console if debugging is enabled.
     * @param {...*} args The arguments to pass to `console.log()`.
     */
    #debugLog() {
        if (this.debug) console.log(...arguments);
    }

    /**
     * Represents the online status for each chat language.
     * @typedef LanguageStatus
     * @prop {boolean} en Whether English chat is online
     * @prop {boolean} es Whether Spanish chat is online
     */

    /**
     * Represents the current chat status as fetched from the API.
     * @typedef {object} ChatStatus
     * @prop {LanguageStatus} online The online status for each chat language.
     * @prop {boolean} badge Whether or not to show the floating badge on the page.
     * @prop {string} timestamp The ISO timestamp of when the chat status was fetched.
     */

    #fetchStatusPromise; // Promise for fetching the chat status
    #hasFetchedStatus; // Indicates whether the first fetch has completed

    /**
     * Fetches the current chat status from the API endpoint.
     * @returns {Promise<ChatStatus>} The current chat status.
     */
    async fetchStatus() {
        this.#debugLog('fetchStatus', this.#statusUrl, this.#fetchStatusPromise);

        // Return a promise that resolves with the updated chat status
        return this.#fetchStatusPromise ??= (async () => {
            const prev = this.#status;

            if (!this.#statusUrl) {
                console.error('The status URL is not configured');
                return prev;
            }

            if (!Util.isAllowedHost(this.#statusUrl)) {
                console.error(`Disallowed fetch url: ${this.#statusUrl}`);
                return prev;
            }

            // Fetch the latest status data JSON from the status URL
            const resp = await fetch(this.#statusUrl);
            const data = await resp.json();

            let [, online] = Util.getMatchingEntry(data, /online/i); // Chat online status
            online = typeof online === 'object'
                ? {en: false, es: false, ...online, ...this.#overrideOnlineStatus} // assign values from online status object
                : {en: !!online, es: !!online, ...this.#overrideOnlineStatus}; // online is not an object; coerce to Boolean and assign to both
            const [, badge] = Util.getMatchingEntry(data, /badge/i); // Floating badge status
            const st = this.#status = Util.deepFreeze({
                online,
                badge: !!badge,
                timestamp: new Date().toISOString()
            });

            // Dispatch events based on whether the data changed
            const onlineChanged = st.online.en !== prev.online.en || st.online.es !== prev.online.es;
            const badgeChanged = st.badge !== prev.badge;
            this.dispatchEvent(new CustomEvent('refresh', {detail: st}));
            if (onlineChanged) this.dispatchEvent(new CustomEvent('online', {detail: st.online}));
            if (badgeChanged) this.dispatchEvent(new CustomEvent('badge', {detail: st.badge}));
            if (onlineChanged || badgeChanged) this.dispatchEvent(new CustomEvent('status', {detail: st}));

            this.#hasFetchedStatus = true;

            return st;
        })().finally(() => this.#fetchStatusPromise = null);
    }

    /**
     * Resets the automatic chat status refresh schedule and optionally changes the length
     * of time between refreshes (in minutes).
     * @param {number} refreshMinutes The number of minutes between refreshes.
     */
    resetScheduledRefresh = (function(ncc) {
        let to, int;

        /** @param {number} refreshMinutes */
        return function(refreshMinutes) {
            if (to) clearTimeout(to);
            if (int) clearInterval(int);
            if (refreshMinutes > 0) ncc.refreshMinutes = refreshMinutes;
            const ms = ncc.refreshMinutes * 60000, // refresh interval in ms
                now = new Date(), // current time
                nextMs = (Math.ceil(now.getTime() / ms) * ms) + 3000, // next multiple of interval plus 3-second buffer
                next = new Date(nextMs), // when to begin regular schedule
                wait = next - now; // ms to wait

            // Wait until the next multiple of the refresh interval, then refresh on that schedule
            ncc.#debugLog(`now: ${now.toISOString()}\nnext: ${next.toISOString()}\nwaiting ${wait / 1000}s, then refreshing every ${ms / 60000}m`);
            to = setTimeout(() => {
                ncc.#debugLog('wait period ended; refreshing: ' + new Date().toISOString());
                ncc.fetchStatus();
                int = setInterval(() => {
                    ncc.#debugLog('scheduled refresh: ' + new Date().toISOString());
                    ncc.fetchStatus();
                }, ms);
            }, wait);
        };
    })(this);

    /** Configures the instance of the element when it is attached to the DOM. */
    connectedCallback() {
        if (this.#initialized) { this.#debugLog('already initialized', this); return; }
        this.#debugLog('initializing', this);
        this.#initialized = true;

        if (NiddkChatControl.#instance && document.querySelectorAll('niddk-chatcontrol').length > 1) {
            console.warn('Extra niddk-chatcontrol was found and removed', this);
            this.remove();
            return;
        }

        // Attach an open shadow root to the custom element
        const shadow = this.attachShadow({ mode: 'open' });

        // Add inline styles
        const style = document.createElement('style');
        style.textContent = 'button:where(.badge){visibility:hidden;}';
        shadow.appendChild(style);

        // Add linked stylesheet
        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.href = Util.getUxAssetUrl('styles/live-chat.css');
        shadow.appendChild(link);

        if (typeof HTMLDialogElement === 'function') {
            // Create the dialog element
            const dialog = this.#dialog = document.createElement('dialog');
            dialog.addEventListener('click', (event) => {
                if (event.target === dialog) dialog.close();
            });
            shadow.appendChild(dialog);
        }

        // Populate properties from attribute values
        const getHref = h => {
            const u = new URL(h || '', location.href),
                p = NiddkChatControl.#noPrechatHref;
            return u.protocol === p ? p : u.href;
        };
        const [href, hrefOn, hrefOff] =
            ['href', 'href-on', 'href-off'].map(a => this.getAttribute(a));
        if (hrefOn || href) this.hrefs.on = getHref(hrefOn || href);
        if (hrefOff || href) this.hrefs.off = getHref(hrefOff || href);

        const getSrc = s => new URL(s || '', NiddkChatControl.#chatServerBaseUrl).href;
        const [src, srcEn, srcEs] = ['src', 'src-en', 'src-es'].map(a => this.getAttribute(a));
        if (srcEn || src) this.srcs.en = getSrc(srcEn || src);
        if (srcEs || src) this.srcs.es = getSrc(srcEs || src);

        const statusLinkId = this.getAttribute('statuslinkid');
        this.#statusUrl = document.getElementById(statusLinkId)?.href || document.body.dataset.livechatstatus;

        // Fetch chat status then set up recurring fetch
        this.fetchStatus().then(() => this.resetScheduledRefresh()).catch((err) => console.error('fetch status failed', err));

        this.loadChatService(); // Call without URL argument attempt to load from current page session

        // Set chat status class on page body
        this.addEventListener('online', ({detail}) => {
            let pageOnline = detail[NiddkChatControl.pageLang];
            document.body.classList[pageOnline ? 'add' : 'remove']('livechat-online');
        });

        // Bind click events of other chat targets (e.g., Contact Us page)
        for (const elt of document.querySelectorAll('[data-trigger-chat]')) {
            const clone = elt.cloneNode(true);
            clone.addEventListener('click', async (event) => {
                event.preventDefault();
                let lang = clone.lang || clone.dataset.triggerChat || '';
                lang = lang.match(/^e[ns]/i) ? lang.slice(0, 2).toLowerCase() : 'en';
                this.#debugLog('handled non-badge chat trigger event', this, event);
                await this.handleBadgeClick(event, lang);
                return false;
            });
            elt.replaceWith(clone); // Replace with clone to ensure no other events are bound
        }

        // Set the tracked instance to this one
        NiddkChatControl.#instance ??= this;
        this.#debugLog('connected', this);
    }

    /**
     * Triggered when observed attributes are changed.
     * @param {string} name The name of the attribute that changed.
     * @param {string} oldValue The old value of the attribute.
     * @param {string} newValue The new value of the attribute.
     */
    attributeChangedCallback(name, oldValue, newValue) {
        if (oldValue === newValue) return;
        this.#debugLog(`attr '${name}' changed: ${Util.logVals({old: oldValue, new: newValue})}`);
        if (name === 'disable-chat' && this.#hasFetchedStatus) this.fetchStatus();
    }

    #dialogUrl; // Currently loaded dialog URL
    #dialogs = {}; // Previously retrieved dialog content
    #dialogPromise; // Promise for retrieving the dialog

    /**
     * Clears out the dialog instance and populates it with content fetched from a page.
     * @param {string|URL} url The URL from which to fetch the dialog content.
     * @param {"en"|"es"=} lang The language to select by default; if omitted, the page language is used.
     * @returns {Promise<HTMLDialogElement>} The populated dialog element.
     * @throws When the dialog content could not be fetched.
     */
    async #prepareDialog(url, lang) {
        if (!this.#dialog) throw new Error('no dialog found');
        return this.#dialogPromise ??= (async () => {
            url = url instanceof URL ? url.href : url;
            lang ||= NiddkChatControl.pageLang;

            if (!Util.isAllowedHost(url)) {
                const msg = `Disallowed url: ${url}`;
                console.error(msg);
                throw new Error(msg);
            }

            if (this.#dialogUrl === url) {
                this.#debugLog(`Dialog for url ${url} is already set up`);
                return this.#dialog;
            }

            let content = this.#dialogs[url]; // Try to get previously-fetched content
            this.#dialog.innerHTML = ''; // Clear the existing dialog content
            if (content) {
                // Dialog previously retrieved; add it to the element
                this.#debugLog(`Swapping previously retrieved dialog from ${url}`);
            } else {
                this.#debugLog(`Fetching dialog content from ${url}`);
                try {
                    // Fetch the dialog content from the url
                    const resp = await fetch(url);
                    const html = await resp.text();

                    // Parse as a DOM document and find the dialog content
                    const doc = (new DOMParser()).parseFromString(html, 'text/html');
                    content = doc.querySelector('.chat-prechat-modal');

                    // Store the result for later retrievals
                    this.#dialogs[url] = content;
                } catch(err) {
                    const msg = `Failed to fetch dialog HTML from ${url}`;
                    console.error(msg, err);
                    throw new Error(msg, {cause: err});
                }
            }

            // Clone a copy of the content
            const clone = content.cloneNode(true);

            // Focus the H2 to avoid Safari automatically using focus-visible on the language toggle
            const h2 = clone.querySelector('h2');
            if (h2) {
                h2.tabIndex = -1;
                h2.autofocus = true;
            }

            // Check the current language option by default
            const ipt = clone.querySelector(`input[value="${lang}"]`);
            if (ipt) ipt.checked = true;

            // Set .chat-langtoggle[data-value] based on radio selection to work around
            // Safari issues with using :has() in the shadow DOM
            const toggle = clone.querySelector('.chat-langtoggle');
            if (toggle) {
                const selLang = toggle.querySelector('input:checked')?.value;
                toggle.dataset.value = selLang;
                toggle.addEventListener('change', (event) => {
                    if (event?.target?.type !== 'radio') return;
                    toggle.dataset.value = event.target.value;
                });
            }

            // Add the content to the dialog and return it
            this.#dialog.appendChild(clone);
            return this.#dialog;
        })().finally(() => this.#dialogPromise = null);
    }

    #cbButton; // The chat service's launch button
    #cbCtrlDiv; // The chat service's control element
    #loadedSrc; // The URL of the script injected into the page
    #chatObserver; // Mutation observer for monitoring changes to the <body> element child list
    #chatCtrlObserver; // Mutation observer for monitoring changes to the chat service control element

    /**
     * Loads the chat service by adding a script tag to the page pointed to the appropriate
     * source URL based on the selected chat language.
     * @param {string|URL=} src The URL of the script to inject. If omitted, the method attempts to load
     * the chat script URL saved in session storage, if any. If omitted and no URL is in session storage,
     * the method does nothing.
     * @param {"en"|"es"=} lang The chat language being loaded (for emitted event detail). If omitted, the method attempts to determine
     * the language from the script URL, based on the configured script source URLs per language. If the language cannot be determined,
     * events will emit a `lang` value of "unknown".
     */
    loadChatService(src, lang) {
        // Determine if chat was already loaded this session
        const sessionSrc = sessionStorage.getItem('loadedChat');
        src ||= sessionSrc;

        // Return if a script was already loaded
        if (!src || this.#loadedSrc) return;

        // Use the provided language or attempt to determine based on the source;
        // this is only used for the emitted events
        lang ??= this.getSourceLang(src) ?? 'unknown';

        const cbCustEltName = 'chatbeacon-button',
            cbCtrlId = 'chatBeaconCtrl',
            beaconBtnSelector = '#beaconBtn',
            hideCbButton = true,
            getButton = (custElt) => {
                custElt ??= document.querySelector(cbCustEltName);
                const cbButton = this.#cbButton = custElt?.shadowRoot?.querySelector(beaconBtnSelector);
                if (cbButton && hideCbButton) cbButton.classList.add('hide');
            };

        // Try to find the chat service button if it is already on the page
        getButton();

        // Set up a mutation observer to monitor for changes to the chat service control element
        let lastChatWinOpen = false;
        const emitChatWin = Util.debounce((opened) => {
            if (opened !== lastChatWinOpen) this.dispatchEvent(new CustomEvent('chatwindow', {detail: {opened, lang, date: new Date()}}));
            lastChatWinOpen = opened;
        }, 200, true);
        const ctrlObs = (this.#chatCtrlObserver ??= new MutationObserver((mutations) => {
            const chg = mutations.map((m) => m.target.className).filter((v,i,a) => a.lastIndexOf(v) === i);
            if (!chg.length) return;
            const isOpen = !!(chg[0] || '').match(/\bopen\b/);
            emitChatWin(isOpen); // emit event on window opened or closed
        }));

        // Set up a mutation observer to monitor for changes to <body> by the chat service
        if (!this.#chatObserver) {
            this.#chatObserver = new MutationObserver((mutations) => {
                const added = mutations.flatMap((m) => [...m.addedNodes]),
                    removed = mutations.flatMap((m) => [...m.removedNodes]),
                    custElt = added.find((n) => n.localName === cbCustEltName),
                    chatCtrl = added.find((n) => n.id === cbCtrlId);
                if (custElt) getButton(custElt);
                if (chatCtrl) {
                    this.#cbCtrlDiv = chatCtrl;
                    this.dispatchEvent(new CustomEvent('chatstart', {detail: {lang, date: new Date()}}));

                    // Monitor for chat window open/close
                    ctrlObs.disconnect(); // Disconnect just in case
                    ctrlObs.observe(chatCtrl, { attributeFilter:['class'] });
                }
                else if (removed.find((n) => n.id === cbCtrlId)) {
                    ctrlObs.disconnect(); // Stop monitoring for chat window close/open
                    this.#cbCtrlDiv = null;
                    this.dispatchEvent(new CustomEvent('chatend', {detail: {lang, date: new Date()}}));

                    // When the chat window is closed and the timeout expires, remove the session
                    // storage so chat is no longer loaded by default when navigating to other pages
                    sessionStorage.removeItem('loadedChat');
                }
            });
            this.#chatObserver.observe(document.body, {childList: true});
        }

        // Find the script element if already on the page
        const scrId = '_lccbscript';
        let script = document.getElementById(scrId) ||
            [...document.querySelectorAll('script[src]')].find((s) => s.src === src);
        if (!script) {
            // If not, add it
            script = document.createElement('script');
            script.id = scrId;
            script.type = 'module';
            script.src = src;
            document.body.appendChild(script);
        }

        this.#loadedSrc = script.src;

        // Store the loaded script URL in the session for when the user navigates between pages
        if (!sessionSrc) sessionStorage.setItem('loadedChat', script.src);
    }

    #cbButtonPromise; // Promise for retrieving the chat service native button

    /**
     * Gets the native trigger button for the chat service.
     * @returns {Promise<HTMLButtonElement>} The button element for launching the chat window.
     */
    async getCbButton() {
        // Check for the button instance to be set every 100ms until it has been
        return this.#cbButton ?? (this.#cbButtonPromise ??= new Promise((resolve, reject) => {
            const timeoutMs = 10000, // 10s
                tryMs = 100, // 100ms
                maxTries = Math.ceil(timeoutMs / tryMs);
            let tries = 0;
            const check = () => {
                tries++;
                if (this.#cbButton) resolve(this.#cbButton);
                else if (tries > maxTries) {
                    reject(`No chat service button found in ${timeoutMs / 1000}s`);
                    this.#cbButtonPromise = null;
                }
                else setTimeout(check, tryMs);
            };
            check();
        }));
    }

    /**
     * Gets the script source URL for the specified language.
     * @param {"en"|"es"} [lang="en"] Either "en" or "es"; "en" is assumed for other values.
     * @returns {string} The script URL.
     */
    getSource(lang) {
        return this.srcs[lang] || this.srcs.en;
    }

    /**
     * Gets the language associated with the specified script source URL. `undefined` is returned
     * if the URL does not match any configured language script URL.
     * @param {string} src The script URL.
     * @returns {"en"|"es"=} Either "en" or "es" if matched, or `undefined` otherwise.
     */
    getSourceLang(src) {
        return Object.entries(this.srcs).find(([_, v]) => v === src) ?? null;
    }

    #badgeClickPromise; // Promise for handling a click on a chat badge

    /**
     * Executes the appropriate action based on chat language and status in response to a click on a chat badge
     * or other chat trigger element.
     * @param {PointerEvent=} event The event instance raised by the click.
     * @param {"en"|"es"|NiddkChatBadge} [badge="en"] The element that was clicked or, if a string, the language ("en" or "es")
     * that was clicked (default: "en").
     */
    async handleBadgeClick(event, badge) {
        return this.#badgeClickPromise ??= (async () => {
            let target = null;
            if (event instanceof PointerEvent) {
                target = event.target; // Set dispatched event target as the element that was clicked
                if (event.ctrlKey !== event.metaKey && event.shiftKey) {
                    event.preventDefault();
                    sessionStorage.removeItem('loadedChat');
                    alert('Your previous chat language selection was cleared.\n\nThe page will now be refreshed.');
                    location.reload();
                    return false;
                }
            }
            let lang, overrideOnline = null;
            if (typeof badge === 'string') lang = badge;
            else if (badge instanceof NiddkChatBadge) {
                target = badge; // Set dispatched event target as the badge element, not the inner button
                lang = badge.lang;
                if (badge.forceState) overrideOnline = badge.online;
            }
            lang ||= 'en';

            // Fetch fresh status on click
            const isOnline = (typeof overrideOnline === 'boolean') ?
                overrideOnline : (await this.fetchStatus()).online[lang];
            const href = this.hrefs[isOnline ? 'on' : 'off'];
            const url = new URL(href, location.href);

            // Emit badge click event
            this.dispatchEvent(new CustomEvent('badgeclick', {detail: {lang, isOnline, target, date: new Date()}}));

            if ((isOnline && this.chatLoaded) || url.protocol === NiddkChatControl.#noPrechatHref) {
                // Launch chat directly
                await this.handleLaunchClick(lang);
            } else if (url.protocol === 'mailto:') {
                // Launch email
                location.href = url;
            } else if (Util.isAllowedHost(url)) {
                // Try to display dialog, or redirect to the prechat page
                try {
                    const dialog = await this.#prepareDialog(url, lang);
                    dialog.showModal();
                } catch {
                    location.href = url;
                }
            } else throw new Error('Invalid live chat configuration');
        })().finally(() => this.#badgeClickPromise = null);
    }

    #launchChatPromise; // Promise for handling a launch chat click

    /**
     * Executes the appropriate action based on a click to trigger opening the chat window.
     * @param {"en"|"es"} [lang="en"] The language ("en" or "es") to launch (default: "en").
     */
    async handleLaunchClick(lang) {
        return this.#launchChatPromise ??= new Promise((resolve, reject) => {
            this.loadChatService(this.getSource(lang), lang);
            this.getCbButton().then((cbButton) => {
                let int, tries = 0;
                cbButton.click();
                if (this.chatWindowOpened) { resolve(); return; }
                const maxTries = 10, wait = 250;
                int = setInterval(() => {
                    const isOpen = this.chatWindowOpened;
                    if (isOpen || tries >= maxTries) {
                        clearInterval(int);
                        if (isOpen) {
                            if (this.#dialog?.open === true) this.#dialog.close();
                            resolve();
                        }
                        else reject(`Chat window did not open after ${tries} clicks`);
                    }
                    if (!isOpen) cbButton.click();
                    tries++;
                }, wait);
            }).catch(reject);
        })
        .then(() => {
            this.#dialog?.close();
            this.dispatchEvent(new CustomEvent('launch', {detail: {lang: lang ?? 'en', date: new Date()}}));
        })
        .finally(() => this.#launchChatPromise = null);
    }

    /**
     * Represents an override for the online status of chat language(s).
     * @typedef LanguageStatusOverride
     * @prop {boolean=} en Whether English chat is online
     * @prop {boolean=} es Whether Spanish chat is online
     */

    /**
     * Gets an object with language online status overrides based on the disable-chat attribute.
     * @returns {LanguageStatusOverride} Languages to override.
     */
    get #overrideOnlineStatus() {
        const obj = {}, val = this.getAttribute('disable-chat');
        if (!val || typeof val !== 'string') return obj;
        if (val.match(/\ben\b/i)) obj.en = false;
        if (val.match(/\bes\b/i)) obj.es = false;
        return obj;
    }

    /** Indicates whether the chat window is opened. */
    get chatWindowOpened() {
        return !!this.#cbCtrlDiv?.classList.contains('open');
    }

    /** Indicates whether the chat service script has been injected into the page. */
    get chatLoaded() {
        return !!this.#loadedSrc;
    }

    /** Gets the configured online and offline chat badge target href values. */
    get hrefs() {
        return this.#hrefs;
    }

    /** Gets the configured chat service script URLs for each language. */
    get srcs() {
        return this.#srcs;
    }

    /** Gets the most recently retrieved chat status. */
    get status() {
        return this.#status;
    }

    /** Gets the URL used to retrieve chat status. */
    get statusUrl() {
        return this.#statusUrl;
    }

    /** Indicates whether debug logging is enabled. */
    get debug() { return this.hasAttribute('debug'); }

    /** Sets whether debug logging is enabled. */
    set debug(val) {
        if (val) this.setAttribute('debug', '');
        else this.removeAttribute('debug');
    }
}

/**
 * Implementation for the NIDDK chat badge `<niddk-chatcontrol>` custom element.
 * @extends HTMLElement
 */
class NiddkChatBadge extends HTMLElement {
    /** Default values for button text labels */
    static #defaultButtonText = {
        en: {
            'title-on': 'Start a chat',
            'title-off': 'Send us an email',
            'label-on': 'Chat live',
            'label-off': 'Write us'
        },
        es: {
            'title-on': 'Iniciar un chat',
            'title-off': 'Envíenos un correo electrónico',
            'label-on': 'Chat en vivo',
            'label-off': 'Chat'
        }
    };

    /**
     * Gets the custom attributes implemented for this element.
     * @returns {string[]} The list of custom attributes.
     */
    static get observedAttributes() {
        return ['debug', 'force-state', 'label-off', 'label-on', 'lang', 'online', 'title-off', 'title-on'];
    }

    constructor() {
        super();
    }

    /**
     * Callback for using a retrieved instance of {@link NiddkChatControl}.
     * @callback chatControlCallback
     * @param {NiddkChatControl} ncc The chat control instance.
     */

    /**
     * Executes a callback function after awaiting an instance of {@link NiddkChatControl}.
     * @param {chatControlCallback} callback The callback function to execute.
     */
    #withChatControl(callback) {
        if (typeof callback !== 'function') return;
        NiddkChatControl.getInstance()
            .then((ncc) => callback(ncc))
            .catch((err) => console.error(err, this));
    }

    /**
     * Writes debug messages to the console if debugging is enabled.
     * @param {...*} args The arguments to pass to `console.log()`.
     */
    #debugLog() {
        this.#withChatControl((ncc) => {
            if (this.debug || ncc.debug) console.log(...arguments, this);
        });
    }

    /**
     * Updates the `title` and `aria-label` attributes of the internal button based on the configured values.
     */
    #setButtonText = Util.debounce(() => {
        const b = this.#button, t = this.buttonTitle;
        if (t) b.title = t;
        else b.removeAttribute('title');
        b.ariaLabel = this.buttonLabel;
        this.#debugLog(`set button text: ${Util.logVals({title: b.title, label: b.label})}`);
    }, 200);

    /**
     * Sets the online status of the badge.
     * @param {object} online An object indicating the online status for each language
     */
    #setOnline(online) {
        if (this.forceState) return;
        if (online.detail) online = online.detail;
        this.online = !!online[this.lang];
        this.#debugLog('set online', online);
    };

    /**
     * Updates the online state of the element based on the current status from the chat controller.
     */
    #updateOnlineState = Util.debounce(() => {
        this.#withChatControl((ncc) => this.#setOnline(ncc.status.online));
    }, 200, true);

    #buttonText = {}; // Override values for button text labels from attribute values
    #button; // The actual trigger button
    #initialized = false; // Flag to indicate if the component has initialized
    #forceState = false; // `true` if online/offline state is forced

    /** Configures the instance of the element when it is attached to the DOM. */
    connectedCallback() {
        if (this.#initialized) { this.#debugLog('already initialized'); return; }
        this.#debugLog('initializing');
        this.#initialized = true;

        // Attach an open shadow root to the custom element
        const shadow = this.attachShadow({ mode: 'open' });

        // Add inline styles
        const style = document.createElement('style');
        style.textContent = 'button:where(.badge){visibility:hidden}';
        shadow.appendChild(style);

        // Add linked stylesheet
        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.href = Util.getUxAssetUrl('styles/live-chat.css');
        shadow.appendChild(link);

        // Create the button element
        const button = this.#button = document.createElement('button');
        button.type = 'button';
        button.className = 'badge';
        this.#setButtonText();
        shadow.appendChild(button);

        const setButtonBusy = (busy) => button.classList[busy ? 'add' : 'remove']('busy');
        button.addEventListener('click', (event) => {
            setButtonBusy(true);
            this.#withChatControl((ncc) =>
                ncc.handleBadgeClick(event, this)
                    .then(() => this.#debugLog('handled badge click'))
                    .catch(console.error)
                    .finally(() => setButtonBusy())
            );
        });


        if (!this.hasAttribute('lang') || !this.lang.match(/^e[ns]$/))
            this.lang = NiddkChatControl.pageLang;

        // Handled forced online/offline state based on the presence/value of the `force-state` attribute
        // If the attribute is present and does not contain 'off' (or 'offline'), online is assumed
        const forceStateVal = this.getAttribute('force-state');
        this.#forceState = typeof forceStateVal === 'string';
        if (this.#forceState) {
            // Use forced online/offline state
            const on = !forceStateVal.match(/\boff/i);
            this.#debugLog('set forced state', on, forceStateVal);
            this.online = on;
        } else {
            // Find the chat controller instance and set up automatic status updates
            this.#withChatControl((ncc) => {
                this.#setOnline(ncc.status.online);
                ncc.addEventListener('online', (data) => this.#setOnline(data));
            });
        }

        this.#debugLog('connected');
    }

    /**
     * Triggered when observed attributes are changed.
     * @param {string} name The name of the attribute that changed.
     * @param {string} oldValue The old value of the attribute.
     * @param {string} newValue The new value of the attribute.
     */
    attributeChangedCallback(name, oldValue, newValue) {
        if (oldValue === newValue) return;
        this.#debugLog(`attr '${name}' changed: ${Util.logVals({old: oldValue, new: newValue})}`);
        if (name === 'lang') this.#updateOnlineState();
        if (name.match(/^(title|label)-/)) this.#buttonText[name] = newValue;
        this.#setButtonText();
    }

    /** Gets the button title/tooltip text based on the online state. */
    get buttonTitle() {
        return this.buttonTitles[this.online ? 'on' : 'off'];
    }

    /** Gets the button accessible label based on the online state. */
    get buttonLabel() {
        return this.buttonLabels[this.online ? 'on' : 'off'];
    }

    /**
     * Gets the configured button title/tooltip text.
     * @returns {object} In the form `{on: "...", off: "..."}`.
     */
    get buttonTitles() {
        // returns {on: '...', off: '...'}
        return Object.fromEntries(['title-on', 'title-off'].map(attr => {
            const attrVal = this.#buttonText[attr];
            return [
                attr.split('-')[1], typeof attrVal === 'string' ? attrVal : NiddkChatBadge.#defaultButtonText[this.lang][attr]
            ];
        }));
    }

    /**
     * Gets the configured button button accessible labels.
     * @returns {object} In the form `{on: "...", off: "..."}`.
     */
    get buttonLabels() {
        // returns {on: '...', off: '...'}
        return Object.fromEntries(['label-on', 'label-off'].map(attr =>
            [
                attr.split('-')[1], this.#buttonText[attr] || NiddkChatBadge.#defaultButtonText[this.lang][attr]
            ]
        ));
    }

    /** Gets the language for the badge ("en" or "es"). */
    get lang() {
        return this.getAttribute('lang') || 'en';
    }

    /** Sets the language for the badge ("en" or "es"). */
    set lang(val) {
        val = (val || 'en').trim().toLowerCase();
        if (!val.match(/^e[ns]$/)) val = 'en';
        if (val !== this.lang) this.setAttribute('lang', val);
    }

    /** Indicates whether the badge is using forced online/offline state. */
    get forceState() {
        return this.#forceState;
    }

    /** Indicates whether debug logging is enabled. */
    get debug() {
        return this.hasAttribute('debug');
    }

    /** Sets whether debug logging is enabled. */
    set debug(val) {
        if (val) this.setAttribute('debug', '');
        else this.removeAttribute('debug');
    }

    /** Indicates whether the badge is online. */
    get online() {
        return this.hasAttribute('online');
    }

    /** Sets whether the badge is online. */
    set online(val) {
        if (val) this.setAttribute('online', '');
        else this.removeAttribute('online');
    }
}

/**
 * Implementation for the NIDDK chat launch button `<niddk-chatlaunch>` custom element.
 * @extends HTMLElement
 */
class NiddkChatLaunch extends HTMLElement {
    /**
     * Gets the custom attributes implemented for this element.
     * @returns {string[]} The list of custom attributes.
     */
    static get observedAttributes() {
        return ['debug', 'lang'];
    }

    constructor() {
        super();
    }

    /**
     * Executes a callback function after awaiting an instance of {@link NiddkChatControl}.
     * @param {chatControlCallback} callback The callback function to execute.
     */
    #withChatControl(callback) {
        if (typeof callback !== 'function') return;
        NiddkChatControl.getInstance()
            .then((ncc) => callback(ncc))
            .catch((err) => console.error(err, this));
    }

    /**
     * Writes debug messages to the console if debugging is enabled.
     * @param {...*} args The arguments to pass to `console.log()`.
     */
    #debugLog() {
        this.#withChatControl((ncc) => {
            if (this.debug || ncc.debug) console.log(...arguments, this);
        });
    }

    #initialized = false; // Flag to indicate if the component has initialized

    /** Configures the instance of the element when it is attached to the DOM. */
    connectedCallback() {
        if (this.#initialized) { this.#debugLog('already initialized', this); return; }
        this.#debugLog('initializing', this);
        this.#initialized = true;

        this.#debugLog('connected', this);

        const buttonText = this.textContent;
        this.innerHTML = '';

        // Attach an open shadow root to the custom element
        const shadow = this.attachShadow({ mode: 'open' });

        // Add keyframes as inline style since they are not exposed to the shadow DOM
        const style = document.createElement('style');
        style.textContent = '@keyframes chatBusy{0%{background-position:-150% 0,-150% 0}50%{background-position:252% 0,-150% 0}100%{background-position:252% 0, 252% 0}}';
        shadow.appendChild(style);

        const button = document.createElement('button');
        button.type = 'button';
        button.className = 'chat-launch';

        const setButtonPart = (busy) => button.part = `launch${busy ? ' busy' : ''}`;
        setButtonPart();

        const span = document.createElement('span');
        span.part = 'text';
        span.textContent = buttonText;

        button.appendChild(span);
        button.addEventListener('click', () => {
            this.#withChatControl((ncc) => {
                setButtonPart(true);
                ncc.handleLaunchClick(this.lang)
                    .then(() => this.#debugLog(`handled launch click for lang=${this.lang}`))
                    .catch(console.error)
                    .finally(() => setButtonPart())
            });
        });

        shadow.appendChild(button);
    }

    /** Gets the language for the launch button ("en" or "es"). */
    get lang() {
        return this.getAttribute('lang') || 'en';
    }

    /** Sets the language for the launch button ("en" or "es"). */
    set lang(val) {
        val = (val || 'en').trim().toLowerCase();
        if (!val.match(/^e[ns]$/)) val = 'en';
        if (val !== this.lang) this.setAttribute('lang', val);
    }
}

// Define the custom element
customElements.define('niddk-chatcontrol', NiddkChatControl);
customElements.define('niddk-chatbadge', NiddkChatBadge);
customElements.define('niddk-chatlaunch', NiddkChatLaunch);

// Expose the chat control class on the window
window.NiddkChatControl = NiddkChatControl;

// Capture chat analytics
NiddkChatControl.getInstance().then((ncc) => {
    const dl = window.dataLayer ||= [];
    const send = (data) => {
        dl.push({
            event: 'live chat',
            liveChat: data
        });
    };
    const getLang = (lang) => lang === 'es' ? 'spanish' : 'english';

    ncc.addEventListener('badgeclick', ({detail: {lang, isOnline, target} = {}}) => {
        const status = isOnline ? 'live' : 'offline';
        let badgeType = 'unknown';
        if (target instanceof NiddkChatBadge) {
            badgeType = target.closest('.chat-floating') ? 'floating' : 'footer';
        } else if (target instanceof HTMLElement) {
            badgeType = target.closest('.button,button,input[type=button],[class*=button]') ? 'in-page button' : 'in-page link';
        }
        send({type: 'badge click', lang: getLang(lang), status, badgeType});
    });

    ncc.addEventListener('chatstart', ({detail: {lang} = {}}) =>
        send({type: 'chat start', lang: getLang(lang)})
    );

    ncc.addEventListener('chatend', () => send({type: 'chat end'}));

    ncc.addEventListener('chatwindow', ({detail: {opened} = {}}) =>
        send({type: `window ${opened ? 'open' : 'close'}`})
    );
});
